/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useRef } from 'react';

import useCarouselScroll from '@common/hooks/useCarouselScroll';
import { ArrowButton, glassArrowCss } from '@stories/atoms/ArrowButton';
import { container, makeResponsiveColumn, row } from '@stories/atoms/Grid';
import RatingStars from '@stories/atoms/RatingStars';
import ReviewTile from '@stories/atoms/ReviewTile';
import { SectionStyled } from '@stories/atoms/Section/Section';
import { media, mediaDown, mq } from '@stories/theming/settings';

import { noScrollbarStyle } from '../../../common/cssUtilities';

import type { TrustedShopReviewsCarouselFragment } from '@gql/hygraph/graphql';

const TrustedBadgeHorizontal = () => (
  <div
    css={mq({
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: '20px',
      display: 'inline-flex',
      gap: '.25rem',
      padding: '.25rem .5rem',
      transform: 'scale(1.3)',
      transformOrigin: ['center', null, null, null, 'left']
    })}
  >
    <RatingStars ratingAverage={5} cssProps={{ justifyContent: 'center' }} />
    <span
      css={(t) => ({
        ...t.font.tiny,
        color: t.color.copy,
        fontWeight: 'bold',
        lineHeight: '100%',
        transform: 'translateY(1px)'
      })}
    >
      4.75/5
    </span>
  </div>
);

const desktopViewBreakpoint = 'desktopLarge';

const ReviewWithBackground: React.FC<TrustedShopReviewsCarouselFragment> = ({
  eTrustedLink,
  headline,
  reviews,
  sectionStyle,
  subheading
}) => {
  const theme = useTheme();
  const sectionSpacing = ['3rem', null, '8rem'];
  const containerRef = useRef<HTMLUListElement>(null);
  const { isScrollable, scrollLeft, scrollPercent, scrollRight } = useCarouselScroll(
    containerRef,
    containerRef
  );

  return (
    <SectionStyled
      {...sectionStyle}
      cssProps={{
        [media(desktopViewBreakpoint)]: { paddingTop: '8rem', paddingBottom: '8rem' }
      }}
    >
      <div
        css={(t) => ({
          ...container,
          maxWidth: 'none',
          position: 'relative',
          zIndex: 1,
          '--container-width': t.section.width.wide
        })}
      >
        <div css={{ ...row, [media(desktopViewBreakpoint)]: { flexWrap: 'nowrap' } }}>
          <div
            className="info-container"
            css={() =>
              mq({
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: sectionSpacing.map((v) => v && `-${v}`),
                paddingTop: sectionSpacing,
                paddingBottom: '2.5rem',
                paddingLeft: 'calc(var(--gutter) / 2)',
                paddingRight: 'calc(var(--gutter) / 2)',
                position: 'relative',
                textAlign: 'center',
                [media(desktopViewBreakpoint)]: {
                  flex: 'calc(var(--container-width) * .25) 0 0',
                  marginBottom: sectionSpacing.map((v) => v && `-${v}`),
                  marginLeft: 'max(0rem, calc(50vw - (var(--container-width) / 2)))',
                  marginRight: 0,
                  paddingBottom: sectionSpacing,
                  textAlign: 'left'
                }
              })
            }
          >
            <div
              css={(t) => ({
                margin: 'auto',
                maxWidth: t.section.width.extraNarrow,
                [media(desktopViewBreakpoint)]: {
                  marginLeft: 'auto',
                  maxWidth: `calc((${t.section.width.extraWide} - 2 * var(--offset) + var(--gutter)) / 4)`,
                  padding: '0 var(--gutter) 0 var(--offset)'
                }
              })}
            >
              {headline && (
                <h2 css={(t) => ({ ...t.headline.bumpy.h2, color: 'white', marginBottom: '1rem' })}>
                  {headline}
                </h2>
              )}
              {eTrustedLink && (
                <a
                  href={eTrustedLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  css={{
                    display: 'block',
                    padding: '1rem 0'
                  }}
                >
                  <TrustedBadgeHorizontal />
                </a>
              )}
              {subheading && (
                <div css={(t) => ({ fontSize: t.font.size.large, marginTop: '1rem' })}>{subheading}</div>
              )}
              {isScrollable && (
                <div
                  css={mq({
                    display: 'flex',
                    gap: '.75rem',
                    justifyContent: ['center', null, null, null, 'flex-start'],
                    marginTop: '1.5rem',
                    [mediaDown('desktop')]: { display: 'none' },
                    [media(desktopViewBreakpoint)]: {
                      flexDirection: 'column-reverse',
                      gap: '.25rem',
                      position: 'absolute',
                      right: '-20px',
                      top: '45%',
                      zIndex: 1
                    }
                  })}
                >
                  <ArrowButton
                    cssProps={{ ...glassArrowCss, transform: 'rotate(180deg)' }}
                    onClick={scrollLeft}
                    disabled={scrollPercent === 0 ? true : false}
                  />
                  <ArrowButton
                    cssProps={{ ...glassArrowCss }}
                    onClick={scrollRight}
                    disabled={scrollPercent === 100 ? true : false}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className="items-container"
            css={mq({
              marginTop: ['null', null, null, null, '-2rem'],
              marginLeft: 'calc(-1 * (var(--offset) - var(--gutter) / 2))',
              marginRight: 'calc(-1 * (var(--offset) - var(--gutter) / 2))',
              width: '100vw',
              [media(desktopViewBreakpoint)]: {
                flex: '50% 1 1',
                width: '50%'
              }
            })}
          >
            <ul
              ref={containerRef}
              css={() =>
                mq({
                  ...noScrollbarStyle,
                  alignItems: 'stretch',
                  color: theme.color.copy,
                  display: 'flex',
                  overflow: 'auto',
                  padding: '0 calc(var(--offset) - var(--gutter) / 2)'
                })
              }
            >
              {reviews.map((review) => (
                <li
                  key={review.id}
                  css={{
                    ...makeResponsiveColumn([11, 6, 4, 3]),
                    [media(desktopViewBreakpoint)]: {
                      flex: 'calc(var(--container-width) / 4 ) 0 0',
                      minHeight: '380px',
                      width: 'calc(var(--container-width) / 4 )'
                    }
                  }}
                >
                  <ReviewTile
                    {...review}
                    eTrustedLogo={true}
                    cssProps={{
                      backdropFilter: 'blur(18px)',
                      backgroundColor: 'hsl(0 0% 100% / .95)',
                      height: 'calc(100% - 32px)'
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </SectionStyled>
  );
};

export default ReviewWithBackground;
