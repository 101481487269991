/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Image from 'next/image';

import { ASSET_URL } from '@utils/constants';

import RatingStars from '../RatingStars';

import type { TrustedShopReviewsCarouselFragment } from '@gql/hygraph/graphql';
import type { CssProps } from '@stories/@types/custom';

export type TReviewTile = TrustedShopReviewsCarouselFragment['reviews'][0] &
  CssProps & {
    eTrustedLogo?: boolean;
  };

const logoOffset = 32;

const ReviewTile: React.FC<TReviewTile> = ({
  author,
  comment,
  date,
  headline,
  rating,
  cssProps,
  eTrustedLogo = false
}) => {
  return (
    <div
      css={(t) => ({
        backgroundColor: 'hsla(0, 0%, 100%, .75)',
        borderRadius: t.border.borderRadius,
        display: 'flex',
        flexDirection: 'column',
        fontSize: t.font.size.small,
        lineHeight: '132%',
        marginTop: `-${logoOffset}px`,
        padding: '1.5rem 1.5rem',
        position: 'relative',
        ...(eTrustedLogo && { marginTop: '32px', paddingTop: '56px' }),
        ...(cssProps as object)
      })}
    >
      {eTrustedLogo && (
        <div
          css={{
            aspectRatio: '1',
            left: 'calc(50% - 32px)',
            position: 'absolute',
            top: `-${logoOffset}px`,
            width: '64px'
          }}
        >
          <Image src={`${ASSET_URL}/asset/images/etrusted-logo.svg`} unoptimized alt="eTrusted Logo" fill />
        </div>
      )}
      <RatingStars ratingAverage={rating} cssProps={{ justifyContent: 'center' }} />
      {headline && (
        <h3 css={{ textTransform: 'uppercase', fontWeight: 'bold', marginTop: '1rem', textAlign: 'center' }}>
          {headline}
        </h3>
      )}
      <div css={css({ margin: '1rem 0', textAlign: 'center' })}>{comment}</div>
      <div
        css={() => ({
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          justifyContent: 'flex-end'
        })}
      >
        <div css={() => ({ fontWeight: 'bold', textAlign: 'center' })}>{author}</div>
        <time css={(t) => ({ color: t.color.grey.g40, textAlign: 'center' })}>
          {new Date(date).toLocaleDateString('de')}
        </time>
      </div>
    </div>
  );
};

export default ReviewTile;
